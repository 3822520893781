import QueueAnim from 'rc-queue-anim'
import { IHomeComponent } from "./types"
import { getChildrenToRender } from "./utils"
import IQRAApp from '../../assets/images/iqraapp.png'

const Banner : React.FC<IHomeComponent> = ({dataSource, isMobile, video, noTitle, isNotHome, ...rest }) => {
  const animType = {
    queue: 'bottom',
    one: {
      y: '+=30',
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    },
  }

  return (
    <div {...rest} {...dataSource.wrapper}>
      <div className="banner5-video">
        <video autoPlay muted loop id="banner5Video">
          <source src={video} type="video/mp4" />
        </video>
      </div>
      <div className="banner5-overlay" />
      <div {...dataSource.page}>
        {!noTitle && (
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}
            componentProps={{
              md: dataSource.childWrapper.md,
              xs: dataSource.childWrapper.xs,
            }}
          >
            <img src={IQRAApp} width="100%" alt="IQRA" />
            {dataSource.childWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
        )}
      </div>
    </div>
  )
}

export default Banner
