import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import IQRAApp from '../../assets/images/icon.png'
import Mail from '../../assets/images/mail.svg'
import Phone from '../../assets/images/phone.svg'
import Whatsapp from '../../assets/images/whatsapp.svg'

export const _HeaderNavDataSource = (isMobile: boolean, headerClassName: string, isAbout?: boolean) => {
  return {
    wrapper: { className: `header3 home-page-wrapper ${headerClassName || ''} ${isMobile ? 'mobile-enabled' : ''}` },
    page: { className: 'home-page' },
    logo: {
      className: 'header3-logo',
      children: Logo,
    },
    isAbout: isAbout,
    Menu: {
      className: 'header3-menu',
      children: [
        {
          name: 'home',
          className: 'header3-item',
          children: {
            href: '/home',
            children: [{ children: 'Home', name: 'text' }],
          },
        },
      ],
    },
    AboutMenu: {
      className: 'header3-menu',
      children: [],
    },
    mobileMenu: { className: 'header3-mobile-menu' },
  }
}

export const HeaderNavDataSource = (isMobile: boolean, headerClassName: string) => {
  return {
    wrapper: { className: `header3 home-page-wrapper ${headerClassName || ''} ${isMobile ? 'mobile-enabled' : ''}` },
    page: { className: 'home-page' },
    logo: {
      className: 'header3-logo',
      children: Logo,
    },
    Menu: {
      className: 'header3-menu',
      children: [
        {
          label: (<Link to='/terms-of-service'>Terms &amp; Conditions</Link>),
          key: 'terms-of-service',
          className: (window.location.href.indexOf("/terms-of-service") > -1) && 'ant-menu-item-selected',
        },
        {
          label: (<Link to='/privacy-policy'>Privacy Policy</Link>),
          key: 'privacy-policy',
          className: (window.location.href.indexOf("/privacy-policy") > -1) && 'ant-menu-item-selected',
        },
        {
          label: (<Link to='/faq'>FAQ</Link>),
          key: 'faq',
          className: (window.location.href.indexOf("/faq") > -1) && 'ant-menu-item-selected',
        },
      ],
    },
    mobileMenu: { className: 'header3-mobile-menu' },
  }
}

export const BannerDataSource = (isNotHome?: boolean) => {
  return {
    wrapper: {
      id: 'home',
      className: isNotHome ? 'home-page-wrapper banner5 element is-not-home' : 'home-page-wrapper banner5 element'
    },
    page: { className: 'home-page banner5-page' },
    childWrapper: {
      className: 'banner5-title-wrapper',
      children: [
        {
          name: 'explain',
          className: 'banner5-explain',
          children: <div>#1 Muslim Assistant for Learning, Tutoring, and Events</div>
        },
        {
          name: 'content',
          className: 'banner5-content',
          children: 'IQRA is FREE Muslim app. What muslims needed just as simple as taking their phone out and find the best Islamic Tutors, References, or Events that suits your need.',
        },
        {
          name: 'button',
          className: 'banner5-button-wrapper',
          children: {
            href: '/download?app=iqra',
            className: 'banner5-button',
            type: 'primary',
            children: 'Download Now',
          },
        },
      ],
    },
  }
}
export const VMBannerDataSource = (isNotHome?: boolean) => {
  return {
    wrapper: {
      id: 'home',
      className: isNotHome ? 'home-page-wrapper banner5 element is-not-home' : 'home-page-wrapper banner5 element'
    },
    page: { className: 'home-page banner5-page' },
    childWrapper: {
      className: 'banner5-title-wrapper',
      children: [
        {
          name: 'explain',
          className: 'banner5-explain',
          children: <div>Vision & Mission</div>
        },
      ],
    },
  }
}
export const MMBannerDataSource = (isNotHome?: boolean) => {
  return {
    wrapper: {
      id: 'home',
      className: isNotHome ? 'home-page-wrapper banner5 element is-not-home' : 'home-page-wrapper banner5 element'
    },
    page: { className: 'home-page banner5-page' },
    childWrapper: {
      className: 'banner5-title-wrapper',
      children: [
        {
          name: 'explain',
          className: 'banner5-explain',
          children: <div>How to monetize your content?</div>
        },
      ],
    },
  }
}
export const FooterDataSource = {
  wrapper: { className: 'home-page-wrapper footer2-wrapper' },
  OverPack: { className: 'home-page footer2', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: [
      {
        name: 'copyright',
        children: 'Copyright © 2023 All rights reserved.',
        className: 'copyright-text',
      },
    ],
  },
  links: {
    className: 'links',
    children: [
      {
        name: 'phone',
        href: 'tel:+34606137234',
        title: 'Call Us',
        className: 'links-phone',
        children: Phone
      },
      {
        name: 'whatsapp',
        href: 'https://wa.me/6281362928575',
        title: 'Whatsapp Us',
        className: 'links-whatsapp',
        children: Whatsapp
      },
      {
        name: 'mail',
        href: 'mailto:hi@iqra.co.id',
        title: 'Email Us',
        className: 'links-mail',
        children: Mail
      },
    ],
  },
}
export const VisionDataSource = {
  wrapper: {
    id: 'about',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 12, xs: 24 },
  img: {
    children: IQRAApp,
  },
  textWrapper: { className: 'content1-text', md: 12, xs: 24 },
  title: { className: 'content1-title', children: 'Vision' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>We dream of changing social media for the better. Our vision is a world where content creators are paid what they deserve, where your data is safe, and where <b>IQRA</b> users shape the platform's future. We want <b>IQRA</b> to be a global hub for creativity and connections, serving as a bridge in our fragmented and polarized global village. Fairness, inclusivity, and privacy are our top priorities. Together, we're building a kinder, more equal digital world.</p>
      </>
    ),
  },
}
export const MissionDataSource = {
  wrapper: {
    id: 'about',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1 row-reverse', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 12, xs: 24 },
  img: {
    children: IQRAApp,
  },
  textWrapper: { className: 'content1-text', md: 12, xs: 24 },
  title: { className: 'content1-title', children: 'Mission' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>We exist to create a fair social media platform that also serves as a bridge in our fragmented and polarized global village. At <b>IQRA</b>, we're all about giving content creators their fair share of ad income. We want to make a social space where people can connect, express themselves, and get paid for it. <b>IQRA</b> is a social media platform run by the <b>IQRA</b> team but owned by everyone.</p>
      </>
    ),
  },
}
export const Feature1DataSource = {
  wrapper: {
    id: 'home-2',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'right',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        title: <span className="title-span">About Us</span>,
        description: (
          <>
            <p>At <b>IQRA</b>, we offer a refreshing and unique alternative to mainstream social media platforms. Our mission is to provide a social space where people can connect, express themselves, and get paid for it.</p>
            <p>Are you tired of the noise and clutter on mainstream social media? Seeking a more personalized and intimate experience? Look no further! <b>IQRA</b> is designed to be an inclusive platform, without distinctions between professional content creators and casual users. Our app provides a trusted space for users to connect with close friends, family, and communities in a more meaningful way.</p>
            <p>What sets us apart is our commitment to a fair distribution of ad revenue. We're proud to share 50% of our advertisement income with all users, ensuring that their efforts are equally rewarded.</p>
          </>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        type: 'logo',
        children: IQRAApp,
      },
    },
  ],
}
export const Feature2DataSource = {
  wrapper: {
    id: 'feature-2',
    className: 'home-page-wrapper feature1-wrapper'
  },
  page: { className: 'home-page feature1' },
  data: [
    {
      className: 'feature1-content',
      type: 'left',
      text: {
        textWrapper: { className: 'feature1-text ant-col-xs-24 ant-col-md-12', md: 12, xs: 24},
        description: (
          <>
            <p>For every user, our app provides a private and secure space to share stories, photos, articles, videos, clips and Live with close friends and family. Robust privacy settings ensure you have full control over who can view your content, safeguarding your personal moments. Users can create private groups, engage in meaningful conversations, and connect with their inner circle, making our app a trusted platform for personal connections.</p>
            <p>With our user-friendly interface, seamless sharing features, and a supportive community, <b>IQRA</b> is the perfect alternative to mainstream social media platforms. Say goodbye to the noise and hello to a more personalized, meaningful, and engaging social media experience.</p>
            <p>Join us today and experience the difference at <b>IQRA</b>. Be part of a vibrant community that empowers users to express themselves, connect with others, and share in the joy of creating and sharing content.</p>
          </>
        )
      },
      img: {
        textWrapper: { className: 'feature1-img ant-col-xs-24 ant-col-md-12', md: 12, xs: 24 },
        children: IQRAApp,
      },
    },
  ],
}
export const HowToHeaderDataSource = {
  wrapper: {
    id: 'how-to-header',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1 content-header', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 12, xs: 24 },
  img: {
    children: IQRAApp,
  },
  textWrapper: { className: 'content1-text', md: 12, xs: 24 },
  title: { className: 'content1-title', children: 'Introducing the IQRA AdSharing Program' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>Earn revenue from your content like never before! With the IQRA AdSharing Program, we offer a unique approach to ad revenue sharing that sets us apart from other social media platforms.</p>
      </>
    ),
  },
}
export const HowToDataSource = {
  wrapper: {
    id: 'how-to',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1 content-no-padding', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 24, xs: 24 },
  img: undefined,
  textWrapper: { className: 'content1-text', md: 24, xs: 24 },
  title: undefined,
  content: {
    className: 'content1-content',
    children: (
      <>
        <span className="content-label">Our Differentiating Factor:</span>
        <p>Unlike other platforms that require complex opt-in processes or additional registration steps to participate in their ad revenue sharing programs, when you sign up as a user on our platform, you automatically become part of the IQRA AdSharing Program, with no minimum requirements such as a certain number of followers or specific viewing time needed to qualify. We believe that every user has the potential to contribute and earn from their content, regardless of their follower count or initial viewership. This inclusive approach ensures that all users have an equal opportunity to participate and benefit from the ad revenue sharing program right from the start.</p>
        <p>In addition, unlike platforms that reward content creators solely based on viewer interaction with the ads displayed alongside their content, IQRA takes a refreshing approach. We allocate 50% of the aggregated gross advertisement revenue and distribute it equally among all users, based on their Screentime.</p>
        <span className="content-label">How It Works:</span>
        <p>Your adsharing revenue on IQRA is directly correlated with viewer interaction on your content, rather than viewer interaction with the ads themselves. We believe that your efforts as a content creator should be recognized and rewarded based on the engagement your content receives, making it a fair and transparent process.</p>
      </>
    ),
  },
}
export const HowToScreenDataSource = {
  wrapper: {
    id: 'how-to-screen',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1 content-no-padding', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 24, xs: 24 },
  img: undefined,
  textWrapper: { className: 'content1-text', md: 24, xs: 24 },
  title: { className: 'content1-title', children: 'Introducing Screentime' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>At IQRA, we believe in providing accurate and fair measurements for your ad revenue sharing. That's why we have implemented the concept of "Screentime" to ensure that your efforts and content engagement are properly recognized.</p>
        <span className="content-label">What is Screentime?</span>
        <p>Screentime refers to the duration during which views on your content are counted for the distribution of ad revenue. It is important to note that Screentime is only considered when the views come from your followers or members of a page (channel or group) where your content is posted.</p>
        <span className="content-label">The Importance of Connection and Engagement</span>
        <p>We understand that your connections, followers, and the members of the pages where your content is posted are the ones who truly engage with your content and contribute to its success. It is with this in mind that we have designed our ad revenue sharing model.</p>
        <span className="content-label">Why Screentime Matters?</span>
        <p>Viewing from random users who are not your connections, followers, or members of the relevant pages where your content is posted is not considered Screentime. This ensures that your ad revenue distribution is based on genuine engagement and interactions within your established community.</p>
        <span className="content-label">Fairness and Accuracy</span>
        <p>By focusing on Screentime, we aim to provide a fair and accurate representation of your content's performance and the revenue generated. It allows us to reward your efforts based on the genuine interest and engagement of your dedicated audience.</p>
      </>
    ),
  },
}
export const HowToSharingDataSource = {
  wrapper: {
    id: 'how-to-sharing',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1 content-no-padding', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 24, xs: 24 },
  img: undefined,
  textWrapper: { className: 'content1-text', md: 24, xs: 24 },
  title: { className: 'content1-title', children: 'Revenue Sharing with Sound Maker and Group Page Maker' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>At IQRA, we believe in recognizing and supporting the collaborative efforts of our users. As part of our commitment to fostering a thriving community, we have implemented revenue sharing with Sound Makers and Group Page Makers, ensuring that everyone involved in the content creation process is rewarded for their contributions.</p>
        <span className="content-label">Sharing Revenue with Sound Maker</span>
        <p>When Clip is created using original sound from another user, we value the collaborative spirit and creativity that goes into it. Therefore, we have established a revenue sharing arrangement where 10% of the Screentime generated by the Clip will be shared with the Sound Maker. This ensures that the Sound Maker is appropriately acknowledged and incentivized for their contribution.</p>
        <span className="content-label">Sharing Revenue with Group Page Maker</span>
        <p>We understand the importance of Group Page in facilitating content discovery and engagement within our community. To recognize the role of Group Page Makers in promoting content and building dedicated member bases, we have designed a revenue sharing model specifically for content posted on group pages.</p>
        <p>For content that is posted on a group page and attracts views from members who are not followers of the content maker, we allocate 30% of the Screentime generated by these members to the Group Page Maker. This revenue sharing mechanism acknowledges the efforts of Group Page Makers in curating engaging content and fostering active communities.</p>
        <span className="content-label">Why Screentime Matters?</span>
        <p>Viewing from random users who are not your connections, followers, or members of the relevant pages where your content is posted is not considered Screentime. This ensures that your ad revenue distribution is based on genuine engagement and interactions within your established community.</p>
        <span className="content-label">Fairness and Transparency</span>
        <p>Our revenue sharing arrangements with Sound Makers and Group Page Makers are designed to promote fairness and transparency within our ecosystem. We believe in recognizing and rewarding the various contributors involved in content creation, thereby encouraging collaboration and community engagement.</p>
      </>
    ),
  },
}
export const HowToLiveDataSource = {
  wrapper: {
    id: 'how-to-live',
    className: 'home-page-wrapper content1-wrapper'
  },
  OverPack: { className: 'home-page content1 content-no-padding', playScale: 0.01 },
  imgWrapper: { className: 'content1-img', md: 24, xs: 24 },
  img: undefined,
  textWrapper: { className: 'content1-text', md: 24, xs: 24 },
  title: { className: 'content1-title', children: 'Introducing Live Stream Gifting' },
  content: {
    className: 'content1-content',
    children: (
      <>
        <p>At IQRA, we are thrilled to offer an exciting feature that allows users to support their favourite live streamers through virtual gifting. With our Live Stream Gifting feature, you can purchase Hearts, virtual gifts, and present them to the live streamers you admire. Let's dive into the details!</p>
        <span className="content-label">How It Works?</span>
        <p>When you join a live stream, you have the option to show your appreciation and support by purchasing Hearts, which represent virtual gifts. These Hearts are a fun and interactive way to engage with live streamers and express your admiration for their content.</p>
        <span className="content-label">Revenue Sharing Model</span>
        <p>To ensure a sustainable platform and cover the necessary expenses, IQRA takes a 50% cut from the in-app purchase of Hearts. This portion is used to cover the App Store and Play Store in-app purchase fees, as well as the costs associated with maintaining and improving our platform.</p>
        <span className="content-label">Using Your Revenue Balance</span>
        <p>In addition to purchasing Hearts with real money, you can also utilize your revenue balance to acquire Hearts. This provides an opportunity for users to utilize their own earnings within the platform to support their favourite live streamers.</p>
        <span className="content-label">Supporting Live Streamers</span>
        <p>Live Stream Gifting is an excellent way to show your support and appreciation for the content creators who bring you joy and entertainment. By purchasing and presenting Hearts during live streams, you are directly contributing to their success and encouraging them to continue producing engaging content.</p>
      </>
    ),
  },
}
