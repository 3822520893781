/* eslint-disable @typescript-eslint/no-unused-vars */
import TweenOne from 'rc-tween-one'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { Button, Col, Form, Input, Row, Typography } from 'antd'
import IQRALogo from '../../assets/images/logo.png'
import MailIcon from '../Icons/Mail'
import {Link as RSLink} from 'react-scroll'

const { Text, Link } = Typography

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

const PreFooter: React.FC<any> = ({isMobile, ...rest }) => {
  const wrapper = { id: 'contact', className: 'home-page-wrapper pre-footer-wrapper' }
  const overPack = { className: 'home-page pre-footer', playScale: 0.05 }

  const onFinish = (values: any) => {
    const data = {
      name: values.name,
      email: values.email,
      subject: values.subject,
      message: values.message,
    }
    
    fetch('https://app.iqra.co.id/mail/mail.php',{
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then((response) => (response.json()))
    .then((response)=>{
      if (response.status === 'success'){
        console.log("Message Sent.")
      }else if(response.status === 'fail') {
        console.log("Message failed to send.")
      }
    })
  }

  return (
    <div {...rest} {...wrapper}>
      <OverPack {...overPack}>
        <TweenOne
          animation={{ x: '+=30', opacity: 0, type: 'from' }}
          key="copyright"
          className="footer-copyright"
        >
          {/* <Row className='footer-top-desc'>
            <Col sm={24}>
              <div className='title-wrapper'>
                <h2>WE LOVE TO HEAR FROM YOU</h2>
              </div>
              <Row style={{marginTop: 50}}>
                <Col sm={10} xs={24}>
                  <div className='pre-description'>
                    <p>If you have a problem that needed to be solved, do not hesitate to get in touch with us. Contact us using form below;</p>
                  </div>
                  <div className='footer-column'>
                    <div className='links'>
                      <Link href='mailto:hi@iqra.co.id' target="_blank"><MailIcon /></Link>
                    </div>
                  </div>
                </Col>
                <Col sm={14} xs={24}>
                  <div className='contact-description'>
                    <Form {...layout} name="IQRALogo-messages" role="form" method="post"  onFinish={onFinish} validateMessages={validateMessages}>
                      <Form.Item name={'name'} label="Name" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={'email'} label="Email" rules={[{ type: 'email', required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={'subject'} label="Subject" rules={[{ required: true }]}>
                        <Input />
                      </Form.Item>
                      <Form.Item name={'message'} label="Message" rules={[{ required: true }]}>
                        <Input.TextArea rows={3} />
                      </Form.Item>
                      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" block htmlType="submit">
                          Send
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row> */}
          <Row>
            <Col md={12} sm={24}>
              <img width={200} src={IQRALogo} alt="IQRA" />
              <div className='footer-column'>
                <Text className='label'>PT IQRA Teknologi Indonesia</Text>
              </div>
              <div className='footer-column'>
                <Text className='label'>Address</Text>
                <Text>Kantor IQRA No. 1, Bogor, Indonesia</Text>
              </div>
              <div className='footer-column'>
                <Text className='label'>Contact Us</Text>
                <Text>hi@iqra.co.id</Text>
              </div>
            </Col>
            <Col  md={12} sm={24}>
              <div className='footer-column with-m-top'>
                <RSLink to='home'>Home</RSLink>
                <Link href='/terms-of-service'>Terms &amp; Conditions</Link>
                <Link href='/privacy-policy'>Privacy Policy</Link>
                <Link href='/faq'>FAQ</Link>
              </div>
            </Col>
          </Row>
        </TweenOne>
      </OverPack>
    </div>
  )
}

export default PreFooter