/* eslint-disable jsx-a11y/iframe-has-title */
import QueueAnim from 'rc-queue-anim'
import TweenOne from 'rc-tween-one'
import { Row, Col } from 'antd'
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack'
import { IHomeComponent } from "./types"

const About : React.FC<IHomeComponent> = ({dataSource, isMobile, video, ...rest }) => {
  const animType = {
    queue: isMobile ? 'bottom' : 'right',
    one: isMobile
      ? {
          scaleY: '+=0.3',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        }
      : {
          x: '-=30',
          opacity: 0,
          type: 'from',
          ease: 'easeOutQuad',
        },
  }

  return (
    <div {...rest} {...dataSource.wrapper}>
      <OverPack {...dataSource.OverPack} component={Row}>
        <QueueAnim
          key="text"
          type={animType.queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
          componentProps={{
            md: dataSource.textWrapper.md,
            xs: dataSource.textWrapper.xs,
          }}
        >
          {dataSource.title && (
            <h1 key="h1" {...dataSource.title}>
              {dataSource.title.children}
            </h1>
          )}
          <div key="p" {...dataSource.content}>
            {dataSource.content.children}
          </div>
        </QueueAnim>
        {dataSource.img && (
          <TweenOne
            key="img"
            animation={animType.one}
            resetStyle
            {...dataSource.imgWrapper}
            component={Col}
            componentProps={{
              md: dataSource.imgWrapper.md,
              xs: dataSource.imgWrapper.xs,
            }}
          >
            <img src={dataSource.img.children} width="50%" alt="IQRA" />
          </TweenOne>
        )}
      </OverPack>
    </div>
  )
}

export default About